<template>
    <v-card flat
            class="bordered-card"
            style="border-radius: 16px; padding: 0; padding-right :10px;">
        <v-layout column style="padding-bottom: 0;">
            <template v-for="d in item.detail">
                <detail-item :detail="d"
                             @openPicture="openPicture($event)"
                             :key="d.idDetail"/>
            </template>
            <order-meta-info :item="item" @openModal="openModal($event)"/>
        </v-layout>
    </v-card>
</template>

<script>
    import DetailItem from "./DetailItem";
    import OrderMetaInfo from "./OrderMetaInfo";

    export default {
        name: "customer-short-order",
        components: {
            OrderMetaInfo,
            DetailItem
        },
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        computed: {
            customerOrderStatus() {
                return this.$store.state.customerOrderStatus
            }
        },
        methods: {
            openPicture(event) {
                this.$emit('openPicture', event)
            },
            openModal(idOrder) {
                this.$emit('openModal', idOrder)
            }
        }
    }
</script>

<style scoped>
    .bordered-card {
        border: 1px solid #E0E0E0;
        margin-top: -5px !important;
        padding: -10px 10px -10px 0;
        background-color: #f2f2f2;
    }
</style>