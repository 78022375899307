<template>
    <v-layout column
              ref="layout">
        <v-layout justify-start column @scroll="onScroll">
            <label class="secondary--text display-1 font-weight-bold">Запросы</label>
            <v-layout align-center style="margin-top: 30px;">
                <label class="title font-weight-bold">Запросы по стране: </label>
                <v-select :items="countryItems"
                          :loading="countriesLoading"
                          no-data-text="Список стран пуст."
                          item-text="title"
                          color="secondary"
                          light
                          :height="20"
                          hide-details
                          filled
                          flat
                          v-model="idCountry"
                          @change="changeCountry"
                          class="secondary--text title font-weight-bold country-select"
                          item-value="idCountry">
                    <template v-slot:selection=" { item } ">
                        <label style="cursor: inherit">{{item.title}}</label>
                    </template>
                    <template v-slot:item=" { item } ">
                        <label style="cursor: inherit"
                               class="color: secondary--text">{{item.title}}</label>
                    </template>
                </v-select>
            </v-layout>
        </v-layout>

        <v-layout style="margin-top: 5px;" :align-center="isMobile"
                  :column="isMobile">
            <label class="subtitle-1">{{totalCount}} {{countTitle}} от {{date | localDate}}</label>
            <v-spacer/>
            <v-menu bottom offset-y :nudge-bottom="10">
                <template v-slot:activator="{ on }">
                    <label class="subtitle-1 font-weight-bold button" v-on="on">
                        Указать дату</label>
                </template>
                <v-date-picker v-model="date"
                               :first-day-of-week="1"
                               locale="ru"
                               @change="changeDate"
                               :min="minDate"
                               :max="maxDate"
                               color="secondary"></v-date-picker>
            </v-menu>

            <div class="delimeter" v-if="!isMobile"></div>
            <label :class="isPrevDayEnabled ? 'button' : 'disabled'"
                   @click="isPrevDayEnabled ? changeDay(-1) : null"
                   class="subtitle-1 font-weight-bold">← Предыдущий день</label>
            <label class="subtitle-1 font-weight-bold"
                   style="margin: 0 20px 0 20px;"
                   :style="{'margin-right': isMobile ? '0' : '20px'}"
                   @click="isNextDayEnabled ? changeDay(1) : null"
                   :class="isNextDayEnabled ? 'button' : 'disabled'">Следующий день →</label>
        </v-layout>

        <template v-for="item in items">
            <customer-item :item="item"
                           @closeOrders="closeOrders"
                           @openPicture="openPictures($event)"
                           @loadOrders="loadOrders($event)"
                           @openModal="openModal($event)"
                           @customerOrdersLoaded="customerOrdersLoaded($event, item)"
                           :selected="item.idCustomer === curCustomer"
                           :key="item.idCustomer"/>
        </template>

        <v-btn v-if="items.length < totalCount"
               style="border-radius: 8px; height: 45px; width :210px;"
               @click="getItems"
               color="secondary">
            Показать ещё {{ordersLeft < perPage ? ordersLeft : perPage}}...
        </v-btn>

        <v-overlay v-model="loading" color="white" absolute>
            <v-progress-circular color="grey" indeterminate/>
        </v-overlay>

        <v-fade-transition>
            <v-btn x-large
                   icon
                   style="width: 70px; height: 70px;"
                   light
                   v-if="isScroll"
                   @click="$vuetify.goTo(0, scrollOptions)"
                   class="scroll-button grey lighten-2">
                <v-icon large color="grey darken-3">mdi-arrow-up</v-icon>
            </v-btn>
        </v-fade-transition>

        <v-dialog  style="z-index :10; box-shadow: none;"
                   width="800"
                   content-class="elevation-0"
                   v-model="showPictures">
            <pictures-carousel :pictures="pictures"
                               :selected-picture="selectedPicture"
                               :is-visible="showPictures"/>
        </v-dialog>

        <v-dialog  width="700"
                   hide-overlay
                   content-class="elevation-0"
                   v-model="showDialog">
            <hide-dialog :item="curItem"
                         :is-visible="showDialog"
                         @blockOrder="blockOrder($event)"
                         @close="showDialog = false"/>
        </v-dialog>
    </v-layout>
</template>

<script>
    import moment from 'moment'

    import PaginationMixin from "../../mixins/PaginationMixin"
    import CustomerItem from "./CustomerItem";
    import PicturesCarousel from "./PIcturesCarousel";
    import HideDialog from "./HideDialog";

    export default {
        components: {
            HideDialog,
            PicturesCarousel,
            CustomerItem
        },
        name: "BuyRequestsList",
        mixins: [PaginationMixin],
        data: () => ({
            countriesLoading: false,
            idCountry: 0,
            date: new Date(),
            perPage: 5,
            loading: false,
            isScroll: false,
            scrollOptions: {
                duration: 200,
                offset: 0,
                easing: 'easeInOutCubic'
            },
            limitPerCustomer: 5,
            curCustomer: null,
            customerOrders: [],
            pictures: [],
            showPictures: false,
            selectedPicture: null,
            curItem: {},
            showDialog: false,
            minDate: new Date(2020, 0, 1, 12).toISOString().substr(0, 10),
            maxDate: new Date().toISOString().substr(0, 10)
        }),
        computed: {
            isMobile() {
                return this.$store.state.isMobile
            },
            countTitles() {
                return ['запрос', 'запросов', 'запроса']
            },
            countTitle() {
                const count = this.totalCount;
                let index = 0;
                if (count < 10 || count > 20) {
                    index = (count % 10 === 1) ? 0 : (count % 10 > 4 || count % 10 === 0) ? 1 : 2;
                    return this.countTitles[index]
                } else return 'запросов'
            },
            countryItems() {
                return this.$store.state.customerCountryItems
            },
            isNextDayEnabled() {
                return this.date < this.maxDate
            },
            isPrevDayEnabled() {
                return this.date > this.minDate
            },
            ordersLeft() {
                return this.totalCount - this.items.length
            },
            customerOrderStatus() {
                return this.$store.state.customerOrderStatus
            }
        },
        watch: {
            '$route'(to) {
                this.getItems(false);
                this.curCustomer = null;
            }
        },
        methods: {
            onScroll(event) {
                this.isScroll = event.target.scrollingElement.scrollTop >= 20
            },
            getCountries() {
                const params = {
                    headers: {token: localStorage.getItem('token')},
                    params: {
                        role: 'customer'
                    }
                };
                this.$http.get("/countries", params)
                    .then(response => {
                        this.$store.dispatch('setCustomerCountryItems', response.data.data);
                    })
                    .catch(error => {
                        console.log(error)
                        switch (error.response.status) {
                            case 406:
                                // this.$router.history.push({name: 'login'})
                                break;
                            case 401:
                                this.$router.history.push({name: 'login'});
                                break;
                            default:
                                console.log('error');
                                break;
                        }
                    })
                    .finally(() => {
                        this.countriesLoading = false
                    });
            },
            getItems(isAdding) {
                this.loading = true;

                const params = {
                    headers: {token: localStorage.getItem('token')},
                    params: {
                        limit: this.perPage,
                        idCountry: this.idCountry,
                        lastId: isAdding ? this.items.slice(-1)[0]["idOrder"] : null,
                        date: moment(this.date, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z"
                    }
                };
                this.$http.get("/orders/date", params)
                    .then(response => {
                        const items = response.data.data.items.map(v => ({...v, customerOrders: []}))
                        if (isAdding)
                            this.items.push(...items);
                        else this.items = items
                        this.totalCount = response.data.data.count
                    })
                    .catch(error => {
                        console.log(error)
                        switch (error.response.status) {
                            case 406:
                                // this.$router.history.push({name: 'login'})
                                break;
                            case 401:
                                this.$router.history.push({name: 'login'});
                                break;
                            default:
                                console.log('error');
                                break;
                        }
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.loading = false
                        }, 500)
                    });
            },
            parseCountry() {
                this.idCountry = parseInt(this.$route.query.idCountry || 1)
            },
            parseDate() {
                let date = Date.parse(this.$route.query.date);
                if (isNaN(date) || date > new Date()) date = new Date();
                else date = new Date(date);
                this.date = date.toISOString().substr(0, 10);
            },
            changeCountry(value) {
                const query = this.$route.query || {};
                this.$router.history.push({
                    name: this.$route.name, query: {...query, idCountry: value}
                })
            },
            changeDate(value) {
                const query = this.$route.query || {};
                this.$router.history.push({
                    name: this.$route.name, query: {...query, date: value}
                })
            },
            changeDay(days) {
                let date = new Date(this.date);
                date.setDate(date.getDate() + days);
                date = date.toISOString().substring(0, 10);
                this.date = date;
                this.changeDate(date);
            },
            loadOrders(idCustomer) {
                this.curCustomer = idCustomer
            },
            closeOrders() {
                this.curCustomer = null;
                this.customerOrders = []
            },
            openPictures(data) {
                this.pictures = data.pictures;
                this.selectedPicture = data.index;
                this.showPictures = true;
            },
            openModal(item) {
                this.curItem = item;
                this.showDialog = true;
            },
            blockOrder() {
                this.items.forEach(v => {
                    if (v.idOrder === this.curItem.idOrder) {
                        v.idStatus = this.customerOrderStatus.hiddenByAdmin;
                        v.closedCount++
                    }

                    v.customerOrders.forEach(p => {
                        if (p.idOrder === this.curItem.idOrder) {
                            p.idStatus = this.customerOrderStatus.hiddenByAdmin
                            v.closedCount++
                        }
                    })
                });
                this.showDialog = false
            },
            customerOrdersLoaded(orders, item) {
                item.customerOrders.push(...orders)
            }
        },
        beforeMount() {
            if (!this.countryItems.length) this.getCountries();
            this.parseCountry();
            this.parseDate()
        },
        created() {
            window.addEventListener('scroll', this.onScroll);
        },
        destroyed() {
            window.removeEventListener('scroll', this.onScroll);
        },
    }
</script>

<style scoped>
    .country-select {
        max-width: 250px;
        margin-left: 15px;
        padding: 0;
    }

    .delimeter {
        height: 30px;
        width: 1px;
        margin: 0 30px 0 30px;
        border-right: 1px solid #BDBDBD;
    }

    .button {
        cursor: pointer;
    }

    .button:hover {
        color: grey
    }

    .disabled {
        color: #BDBDBD;
    }

    .scroll-button {
        position: fixed;
        right: 35px;
        bottom: 35px;
    }

    .clickable:hover {
        color: grey;
        cursor: pointer;
    }
</style>