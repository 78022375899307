<template>
    <v-layout column align-center>
        <v-carousel v-model="model"
                    height="700"
                    hide-delimiters>
            <v-carousel-item
                    v-for="(item, i) in items"
                    :key="i">
                <v-img :src="item"
                       :lazy-src="defaultImage"
                       contain
                       @error="handleImageError($event, i)"
                       aspect-ratio="1"
                       max-height="680"
                       max-width="800">
                </v-img>
            </v-carousel-item>
        </v-carousel>
        <div class="title font-weight-regular white--text" style="margin-top: 30px;">
            {{ model + 1 }} из {{pictures.length}} фото</div>
    </v-layout>
</template>

<script>
    export default {
        name: "pictures-carousel",
        props: {
            pictures: {
                type: Array,
                required: true,
            },
            selectedPicture: {
                type: Number,
                required: true
            },
            isVisible: {
                type: Boolean,
                required: true
            }
        },
        data: () => ({
            model: null,
            imageErrors: {},
            items: [],
            defaultImage: require("@/assets/default.png")
        }),
        watch: {
            isVisible(to) {
                this.imageErrors = {};
                this.model = this.selectedPicture
                this.items = [...this.pictures]
            }
        },
        methods: {
            handleImageError(event, ind) {
                this.items[ind] = this.defaultImage;
            },
        },
        beforeMount() {
            this.items = [...this.pictures]
        }
    }
</script>

<style scoped>

</style>