<template>
    <v-layout class="subtitle-2 font-weight-regular"
              style="min-width: 40%; max-width: 40%;"
              align-start>
        <v-icon small>mdi-car</v-icon>
        <v-layout row wrap style="max-width: 200px; margin-left: 5px; margin-top: -2px;">
            {{car.titleCarBrand}}, {{car.titleCarModel}}
            {{addCarDetail('titleCArGeneration')}}
            {{addCarDetail('titleCarSerie')}}
            {{addCarDetail('titleCarTrim')}}
        </v-layout>
    </v-layout>
</template>

<script>
    export default {
        name: "car-label",
        props: {
            car: {
                type: Object,
                required: true
            }
        },
        methods: {
            addCarDetail(key) {
                return this.car[key] ? `, ${this.car[key]}` : ''
            }
        }
    }
</script>

<style scoped>

</style>