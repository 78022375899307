<template>
    <v-layout column>
        <v-card flat
                class="bordered-card"
                :style="{'background-color': item.customerOrders.length ? '#f2f2f2' : ''}"
                style="border-radius: 16px;">
            <v-layout fill-height>
                <v-layout
                        column
                        align-center
                        justify-space-between
                        class="info-layout">
                    <label class="subtitle-1 font-weight-bold">{{item.phone}}</label>
                    <label class="subtitle-1 font-weight-bold clickable"
                           style="margin-top: 20px;"
                           @click="loadOrders"
                           v-if="item.ordersCount > 1">
                        Ещё {{item.ordersCount}} {{countTitle}}
                        <v-icon v-if="!item.customerOrders.length">mdi-chevron-down</v-icon>
                        <v-icon v-else>mdi-chevron-up</v-icon>
                    </label>
                </v-layout>

                <v-layout column>
                    <v-layout column style="padding-bottom: 10px;">
                        <template v-for="d in item.detail">
                            <detail-item :detail="d"
                                         @openPicture="openPicture($event)"
                                         :key="d.idDetail"/>
                        </template>
                    </v-layout>

                    <v-layout v-if="item.customerOrders.length">
                        <order-meta-info :item="item" @openModal="openModal($event)"/>
                    </v-layout>
                </v-layout>
            </v-layout>
        </v-card>

        <template v-if="item.customerOrders.length">
            <v-slide-y-transition mode="out-in" appear>
                <v-layout column
                          justify-start
                          style="padding-left: 210px;"
                          key="1">
                    <v-slide-y-transition group appear tag="div">
                        <customer-short-order v-for="order in item.customerOrders"
                                              :item="order"
                                              @openModal="openModal($event)"
                                              @openPicture="openPicture($event)"
                                              :key="order.idOrder"/>
                    </v-slide-y-transition>

                    <label class="subtitle-1 font-weight-bold clickable"
                           @click="getCustomerOrders"
                           v-if="ordersLeft"
                           style="margin-bottom: 30px; margin-top: -5px;">
                        Показать еще {{perPage < ordersLeft ? perPage : ordersLeft}}...
                    </label>
                </v-layout>
            </v-slide-y-transition>
        </template>
    </v-layout>
</template>

<script>
    import DetailItem from "./DetailItem";
    import CustomerShortOrder from "./CustomerShortOrder";
    import OrderMetaInfo from "./OrderMetaInfo";

    export default {
        components: {
            OrderMetaInfo,
            DetailItem,
            CustomerShortOrder
        },
        name: "customer-item",
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            showFullInfo: false,
            perPage: 3,
            page: 0
        }),
        computed: {
            countTitles() {
                return ['запрос', 'запросов', 'запроса']
            },
            countTitle() {
                const count = this.item.ordersCount;
                let index = 0;
                if (count < 10 || count > 20) {
                    index = (count % 10 === 1) ? 0 : (count % 10 > 4 || count % 10 === 0) ? 1 : 2;
                    return this.countTitles[index]
                } else return 'запросов'
            },
            ordersLeft() {
                return this.item.ordersCount - this.item.customerOrders.length
            },
            customerOrderStatus() {
                return this.$store.state.customerOrderStatus
            }
        },
        watch: {
            '$route'(to) {
                this.item.customerOrders = [];
                this.page = 0;
            }
        },
        methods: {
            loadOrders() {
                if (this.item.customerOrders.length) {
                    this.item.customerOrders = [];
                    this.page = 0;
                }
                else {
                    this.getCustomerOrders();
                    this.$emit('loadOrders', this.item.idCustomer)
                }
            },
            getCustomerOrders() {
                const params = {
                    headers: {token: localStorage.getItem('token')},
                    params: {
                        limit: this.perPage,
                        idOrder: this.item.idOrder,
                        page: this.page,
                        idCustomer: this.item.idCustomer
                    }
                };
                this.$http.get("/orders/customer", params)
                    .then(response => {
                        this.$emit('customerOrdersLoaded', response.data.data);
                        this.page++;
                    })
                    .catch(error => {
                        console.log(error)
                        switch (error.response.status) {
                            case 406:
                                // this.$router.history.push({name: 'login'})
                                break;
                            case 401:
                                this.$router.history.push({name: 'login'});
                                break;
                            default:
                                console.log('error');
                                break;
                        }
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.loading = false
                        }, 500)
                    });
            },
            openPicture(event) {
                this.$emit('openPicture', event);
            },
            openModal(item) {
                this.$emit('openModal', {
                    ...item,
                    closedCount: this.item.closedCount,
                    idCustomer: this.item.idCustomer
                });
            }
        }
    }
</script>

<style scoped>
    .bordered-card {
        border: 1px solid #E0E0E0;
        margin: 15px 0 15px 0;
        padding: 0 10px 0 10px;
        transition-property: background-color;
        transition-duration: 0.2s;
    }

    .bordered-card:hover {
        background-color: #f2f2f2;
    }

    .info-layout {
        min-width: 200px;
        max-width: 200px;
        padding-top: 10px;
        padding-bottom: 20px;
        border-right: 1px solid #E0E0E0;
    }

    .clickable:hover {
        color: grey;
        cursor: pointer;
    }
</style>