<template>
    <v-layout class="subtitle-2 font-weight-regular"
              style="min-width: 40%; max-width: 40%;"
              align-start>
        <v-layout class="subtitle-2" style="margin-left: 15px;" align-start>
            <v-icon small>mdi-web</v-icon>
            <v-layout row wrap style="margin-left: 5px; margin-top: -3px;">
                <template v-for="(c, ind) in loctions">
                    <label v-if="c.isAllCountry">
                         All {{c.titleCountry}}
                    </label>
                    <label v-else-if="Object.values(c.cities).length < maxCityCount">
                        {{Object.values(c.cities).join(", ")}}
                    </label>
                    <label v-else>
                        {{Object.values(c.cities).slice(0, maxCityCount).join(", ")}}
                        + {{Object.values(c.cities).length - maxCityCount}} other
                    </label>
                    <label v-if="ind !== loctions.length - 1">, </label>
                </template>
            </v-layout>
        </v-layout>
    </v-layout>
</template>

<script>
    export default {
        name: "country-label",
        props: {
            location: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            maxCityCount: 3
        }),
        computed: {
            loctions() {
                return Object.values(this.location).sort((v, p) => v.isAllCountry >= p.isAllCountry)
            }
        }
    }
</script>

<style scoped>

</style>