<script>
    export default {
        name: "PaginationMixin",
        data() {
            return {
                page: parseInt(this.$route.query.page || 1),
                totalCount: 0,
                perPage: 10,
                loading: false,
                items: []
            }
        },
        computed: {
            pagesCount() {
                return Math.ceil(this.totalCount / this.perPage) || 1
            }
        },
        mounted() {
            this.getItems()
        }
    }
</script>