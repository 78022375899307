<template>
    <v-layout style="padding-left: 20px; margin-top: 20px; padding-bottom: 20px;">
        <label style="min-width: 350px; max-width: 350px;"
               class="subtitle-2 font-weight-regular">
            от {{item.createDate | localDate}} </label>
        <v-layout>
            <car-label :car="item.car"/>
            <country-label :location="item.location"/>

            <v-layout justify-end
                      align-start
                      style="margin-top: -5px; margin-left: 10px; padding-right: 10px; min-width: 20%;  max-width: 20%;">
                <v-hover
                        v-slot:default="{ hover }"
                >
                    <v-layout justify-end align-center>
                        <template v-if="item.idStatus === customerOrderStatus.hiddenByAdmin">
                            <v-icon color="grey lighten-1">mdi-eye-off</v-icon>
                            <label class="subtitle-1 grey--text"
                                   style="margin-left :5px; margin-right: 25px; color: #BDBDBD;">Скрыт</label>
                        </template>

                        <template v-else>
                            <v-icon :color="hover ? 'grey' : 'grey darken-1'"
                                    :style="{cursor: hover ? 'pointer' : 'default'}"
                                    @click="openModal">mdi-eye-off</v-icon>
                            <label class="subtitle-1"
                                   @click="openModal"
                                   :style="{color: hover ? '#9E9E9E' : '#757575', cursor: hover ? 'pointer' : 'default'}"
                                   style="margin-left :5px;">Скрыть...</label>
                        </template>
                    </v-layout>
                </v-hover>
            </v-layout>
        </v-layout>
    </v-layout>
</template>

<script>
    import CarLabel from "./CarLabel";
    import CountryLabel from "./CountryLabel";

    export default {
        components: {
            CountryLabel,
            CarLabel
        },
        name: "order-meta-info",
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        computed: {
            customerOrderStatus() {
                return this.$store.state.customerOrderStatus
            }
        },
        methods: {
            openModal() {
                this.$emit('openModal', this.item)
            }
        }
    }
</script>

<style scoped>

</style>