<template>
    <v-form ref="form">
        <v-card>
            <v-card-title class="display-1 font-weight-bold" style="padding-top: 30px;">
                <v-layout align-center justify-space-between>
                    <label>Скрыть запрос?</label>

                    <v-btn icon large @click="$emit('close')">
                        <v-icon large>mdi-close</v-icon>
                    </v-btn>
                </v-layout>
            </v-card-title>
            <v-card-text style="padding-bottom: 0;">
                <v-layout column>
                    <template v-for="detail in item.detail">
                        <v-layout class="detail-layout" align-start>
                            <label class="subtitle-1 font-weight-bold">{{detail.title}}</label>
                            <label class="subtitle-1 font-weight-regular body-label text-truncate"
                                   v-if="detail.body">
                                {{detail.body}}</label>
                            <label class="subtitle-1 font-weight-regular body-label grey--text"
                                   v-else>Без описания</label>
                        </v-layout>
                    </template>

                    <label class="subtitle-1" style="margin-top: 30px; line-height: 10pt;">
                        Дополнительное сообщение будет отправлено на почту покупателя.
                    </label>
                    <label class="subtitle-1">Необязательно для заполнения.</label>

                    <v-textarea v-model.trim="text"
                                style="margin-top: 20px;"
                                rows="4"
                                class="subtitle-1"
                                no-resize
                                filled
                                placeholder="Дополнительное сообщение"/>

                    <v-layout align-center style="margin-top: -20px;">
                        <v-checkbox color="secondary"
                                    v-model="check"
                                    :disabled="loading"
                                    label="Заблокировать покупателя"/>
                        <v-spacer/>
                        <label class="subtitle-1">Скрытых запросов покупателя</label>
                        <label class="title font-weight-regular"
                               style="margin-left :5px; margin-top: -2px;">{{item.closedCount}}</label>
                    </v-layout>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-layout align-center style="padding-bottom: 30px;">
                    <v-btn style="width: 220px; height :45px; margin-left: 20px; border-radius: 8px;"
                           @click="blockOrder"
                           @loading="loading"
                           color="secondary">
                        Скрыть запрос
                    </v-btn>
                    <label class="subtitle-2 font-weight-regular"
                           style="margin-left: 25px;">Запрос нельзя восстановить.</label>
                </v-layout>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
    export default {
        name: "hide-dialog",
        props: {
            item: {
                type: Object,
                required: false
            },
            isVisible: {
                type: Boolean,
                required: true
            }
        },
        data: () => ({
            text: "",
            check: false,
            loading: false,
            textRules: [
                v => {
                    if (!v) return true;
                    return !!v && v.trim().length < 4000 || 'Максимум - 4000 символов'
                }
            ]
        }),
        watch: {
            isVisible(to) {
                this.text = "";
                this.check = false
            }
        },
        methods: {
            blockUser() {
                const params = {
                    headers: {token: localStorage.getItem('token')}
                };
                const postData = {
                    idCustomer: this.item.idCustomer,
                    isBlocked: true
                };
                this.$http.post("/customers/status", postData, params)
            },
            blockOrder() {
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    const params = {
                        headers: {token: localStorage.getItem('token')}
                    };
                    let postData = {
                        idOrder: this.item.idOrder
                    };
                    if (this.text) postData.message = this.text;

                    this.$http.post("/orders/block", postData, params)
                        .then(response => {
                            this.$emit('blockOrder');
                        })
                        .catch(error => {
                            console.log(error)
                            switch (error.response.status) {
                                case 406:
                                    // this.$router.history.push({name: 'login'})
                                    break;
                                case 401:
                                    this.$router.history.push({name: 'login'});
                                    break;
                                default:
                                    console.log('error');
                                    break;
                            }
                        })
                        .finally(() => {
                            this.loading = false
                        });

                    if (this.check) {
                        this.blockUser()
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .detail-layout {
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 0px 12px 12px 12px;
        padding: 15px;
        margin-top: 10px;
    }

    .body-label {
        margin-left: 5px;
    }
</style>