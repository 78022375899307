<template>
    <v-layout style="padding-left: 20px; margin-top: 10px;">
        <v-layout style="min-width: 350px; max-width: 350px;">
            <label class="subtitle-1 black--text" style="word-break: normal">
                {{detail.title}}</label>
        </v-layout>

        <v-layout column>
            <label class="subtitle-1 black--text" v-if="detail.body">{{detail.body}}</label>
            <label class="subtitle-1 grey--text" v-else>Без описания</label>

            <v-layout v-if="detail.photos" style="margin-top: 10px;">
                <template v-for="(p, ind) in detail.photos">
                    <v-hover v-slot:default="{ hover }">
                        <v-card style="border-radius: 8px;">
                            <v-img
                                    :src="imageErrors[ind] ? defaultImage : p.iconResolution"
                                    @error="handleImageError($event, ind)"
                                    lazy-src="../../assets/default.png"
                                    max-height="72px"
                                    max-width="72px"
                                    height="72px"
                                    width="72px"
                            >
                                <v-fade-transition>
                                    <v-layout v-if="hover"
                                              align-center
                                              @click="openPicture(ind)"
                                              justify-center fill-height>
                                        <v-icon>mdi-eye</v-icon>
                                    </v-layout>
                                </v-fade-transition>
                            </v-img>
                        </v-card>
                    </v-hover>
                </template>
            </v-layout>
        </v-layout>
    </v-layout>
</template>

<script>
    export default {
        name: "detail-item",
        props: {
            detail: {
                type: Object,
                required: true
            }
        },
        data:() => ({
            imageErrors: {},
            defaultImage: "../../assets/default.png"
        }),
        computed: {

        },
        methods: {
            handleImageError(event, ind) {
                this.imageErrors[ind] = true;
            },
            openPicture(index) {
                let pictures = this.detail.photos.map(v => v.standartResolution);
                this.$emit('openPicture', {pictures: pictures, index: index});
            }

        }
    }
</script>

<style scoped>

</style>