<template>
    <v-layout class="content-layout"
              column>
        <buy-requests-list/>
    </v-layout>
</template>

<script>
    import BuyRequestsList from "../components/buyRequests/BuyRequestsList";
    export default {
        name: "BuyRequestsView",
        components: {BuyRequestsList}
    }
</script>

<style scoped>

</style>